<script lang="ts" setup>
import type { File } from '~/types/api'

interface RadioButtonProps {
    id?: string
    label?: string
    modelValue?: string | number
    value?: string | number
    disabled?: boolean
    required?: boolean
    name?: string
    image?: File
}
const props = withDefaults(defineProps<RadioButtonProps>(), {
    disabled: false,
    required: false,
})
const emit = defineEmits(['update:modelValue'])

const selected = computed<boolean>(() => {
    return props.modelValue === props.value
})
const modelValue = computed<string | number | undefined>({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})
</script>

<template>
    <label
        :for="id"
        :class="[$style.root, selected && $style.selected]"
    >
        <input
            :id="id"
            v-model="modelValue"
            type="radio"
            :name="name"
            :value="value"
        >
        <NuxtImg
            v-if="image && image.processable"
            provider="interventionRequest"
            width="92px"
            height="auto"
            :srcset="null"
            format="webp"
            :quality="50"
            :src="image.relativePath"
            :alt="label || ''"
            :class="$style.image"
        />
        {{ label }}
    </label>
</template>

<style lang="scss" module>
.root {
    display: flex;
    overflow: hidden;
    height: 46px;
    align-items: center;
    padding: var(--spacing-xs) var(--spacing-2-xs);
    border: 1px solid rgb(1 1 1 / 20%);
    border-radius: var(--radius-sm);
    color: var(--color-primary);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    gap: 6px;
    line-height: 1;
    user-select: none;

    &:focus {
        border-color: rgb(1 1 1);
    }

    @media (hover: hover) {
        &:hover {
            border-color: rgb(1 1 1);
        }
    }

    input {
        position: absolute;
        left: -9999px;
    }
}

.image {
    width: 46px;
}

.selected {
    border-color: #313131;
    background: #313131;
    color: #fff;
}
</style>
