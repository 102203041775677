<script lang="ts" setup>
import type { File } from '~/types/api'

interface RadioButtonGroupProps {
    modelValue?: string | number
    disabled?: boolean
    required?: boolean
    name?: string
    options: {
        id: string
        label?: string
        value?: string | number
        image?: File
    }[]
}
const props = withDefaults(defineProps<RadioButtonGroupProps>(), {
    disabled: false,
    required: false,
})
const emit = defineEmits(['update:modelValue'])

const modelValue = computed<string | number | undefined>({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const slideIndex = ref(0)
const carouselEnabled = ref(false)
</script>

<template>
    <VCarousel
        v-slot="{ slideClass }"
        v-model="slideIndex"
        :class="[$style.root, carouselEnabled && $style['root--carousel-enabled']]"
        async-slides
        @enable="carouselEnabled = true"
        @disable="carouselEnabled = false"
    >
        <VCarouselAsyncSlide
            v-for="option in options"
            :key="option.id"
            v-slot="{ setRef }"
        >
            <VRadioButton
                :id="option.id"
                :ref="(el) => setRef(el)"
                v-model="modelValue"
                :class="[...slideClass]"
                :value="option.value"
                :label="option.label"
                :image="option.image"
            />
        </VCarouselAsyncSlide>
    </VCarousel>
</template>

<style lang="scss" module>
.root {
    --v-carousel-slide-width: none;
    --v-carousel-slide-margin-right: var(--spacing-xs);
    --v-carousel-last-slide-margin-right: #{rem(48)};

    position: relative;
    overflow: hidden;

    &--carousel-enabled::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: rem(48);
        background: linear-gradient(to right, transparent, #fff);
        content: '';
    }
}
</style>
